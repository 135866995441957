import { autoinject } from 'aurelia-framework';
import { DataFilter } from 'lib/tables/DataFilter';
import { CreateMooringStation, MooringStation, MooringStationListItem } from 'models/MooringStation';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringStationService extends BaseApiService<MooringStation> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringStation);
  }

  public async update(data: CreateMooringStation, id: number) {
    await this.httpClient.exec(`${CreateMooringStation.ApiUrl}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  public async create(data: CreateMooringStation) {
    return await this.httpClient.exec<MooringStation>(`${CreateMooringStation.ApiUrl}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async table(filters: DataFilter) {
    if (filters.export) {
      // const f = await this.httpClient.execNoTransform(`api/serviceInvoices/invoiceable${filters.toQueryString()}`, {
      //   method: 'GET',
      // });
      // return f.blob().then((blob) => {
      //   const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
      //   saveAs(blob, `service-invoiceable-report-${dateString}.xlsx`);
      // });
    } else {
      return await this.httpClient.execWithResponseMeta<MooringStationListItem[]>(
        `${MooringStation.ApiUrl}/list${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }
}
