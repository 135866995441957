import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { LinkAnalysisDto, UnlinkAnalysisDto } from 'models/NetDimension';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NetDimensionService extends BaseApiService<Models.NetDimension> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.NetDimension);
  }

  public calculateValues(netId: number, netDimension: Models.NetDimension): Promise<Models.NetDimension> {
    return this.post(netDimension, '/calculate-values/' + netId);
  }

  public linkAnalysis(netDimensionId: number, dto: LinkAnalysisDto): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.post(dto as any, `/${netDimensionId}/link-analysis`) as unknown as any;
  }

  public unLinkAnalysis(netDimensionId: number, dto: UnlinkAnalysisDto): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.post(dto as any, `/${netDimensionId}/unlink-analysis`) as unknown as any;
  }
}
