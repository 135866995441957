import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NettingService extends BaseApiService<Models.Netting> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Netting);
  }

  public getNettingStrengthRule(dimensionClassId: number, halfMeshSize: number): Promise<any> {
    return this.get(`get-netting-strength-rule/${dimensionClassId}/${halfMeshSize}`);
  }

  public getNettingStrengthRuleByNetId(netId: number, halfMeshSize: number): Promise<any> {
    return this.get(`get-netting-strength-rule-for-net/${netId}/${halfMeshSize}`);
  }

  public duplicate(nettingId: number): Promise<unknown> {
    return this.post(null, `/duplicate/${nettingId}`) as Promise<unknown>;
  }

  public validateNettings(netId: number): Promise<any> {
    return this.get('validate-nettings/' + netId);
  }

  public getNettingDataForNet(netId: number): Promise<any> {
    return this.get('get-netting-data-for-net/' + netId);
  }

  public saveNettingDataForNet(netId: number, nettingData: any): Promise<any> {
    return this.post(nettingData, '/save-netting-data-for-net/' + netId);
  }
}
