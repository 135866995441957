export class ListSettingsService {
  public getDefaultPageSize(): Promise<number> {
    return new Promise((resolve, reject) => {
      const userViewPreferences: string = localStorage.getItem('LIST_SETTINGS_GLOBAL');
      if (userViewPreferences) {
        const viewPreferencesData: any = JSON.parse(userViewPreferences);

        if (!viewPreferencesData.pageSize) {
          resolve(25);
        }

        return resolve(viewPreferencesData.pageSize);
      } else {
        return resolve(25);
      }
    });
  }
}
