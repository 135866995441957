import { autoinject } from 'aurelia-framework';
import { BaseApiService } from './base-api-service';
import { Models } from 'models/core';
import { AuthHttpClient } from './auth-http-client';
import { Filters } from 'components/reports/newnet-antifouling-report';

@autoinject
export class NewnetAntifoulingReportService extends BaseApiService<Models.NewNetAntifoulingReport> {
    constructor(httpClient: AuthHttpClient) {
        super (httpClient, Models.NewNetAntifoulingReport);
    }

    public getNewnetAntifoulingReport(){
        return this.getResponse('get-newnet-antifouling-report');
    }

    public getNewNetAntifoulingReportList(
        filters: Filters,
    ): Promise<Response> {
        const params = this.getFilterQueryParams(filters);

        return this.getResponse('get-newnet-antifouling-report?' + params.toString());
    }

    public getFilterQueryParams(filters: Filters): URLSearchParams {
        const params = new URLSearchParams();

        for (const [key, value] of Object.entries(filters)) {
            if(value) {
                params.append(key, `${value}`)
            }
        }
        return params;
    }
}