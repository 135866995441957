import { autoinject } from 'aurelia-framework';
import { DataFilter } from 'lib/tables/DataFilter';
import { CreateMooringArticle, MooringArticle, MooringArticleListItem } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringArticleService extends BaseApiService<MooringArticle> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringArticle);
  }

  public async hasSimilar(data: CreateMooringArticle) {
    return await this.httpClient.exec<{
      articleId: number;
    }>(`${CreateMooringArticle.ApiUrl}/has-similar-article`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async update(data: CreateMooringArticle, id: number) {
    await this.httpClient.exec(`${CreateMooringArticle.ApiUrl}/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  public create(data: CreateMooringArticle) {
    return this.httpClient.exec<MooringArticle>(`${CreateMooringArticle.ApiUrl}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public table(filters: DataFilter) {
    if (filters.export) {
      // const f = await this.httpClient.execNoTransform(`api/serviceInvoices/invoiceable${filters.toQueryString()}`, {
      //   method: 'GET',
      // });
      // return f.blob().then((blob) => {
      //   const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
      //   saveAs(blob, `service-invoiceable-report-${dateString}.xlsx`);
      // });
    } else {
      return this.httpClient.execWithResponseMeta<MooringArticleListItem[]>(
        `${MooringArticle.ApiUrl}/list${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }

  public async removeArticleProduct(mooringArticleId: number, articleProductId: number) {
    await this.httpClient.execNoTransform(
      `${MooringArticle.ApiUrl}/${mooringArticleId}/article-product/${articleProductId}`,
      {
        method: 'DELETE',
      }
    );
  }

  public async removeMaterial(mooringArticleId: number, articleMaterialId: number) {
    await this.httpClient.execNoTransform(
      `${MooringArticle.ApiUrl}/${mooringArticleId}/article-material/${articleMaterialId}`,
      {
        method: 'DELETE',
      }
    );
  }
}
