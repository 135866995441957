import { autoinject } from 'aurelia-framework';
import { DataFilter } from 'lib/tables/DataFilter';
import { CreateUpdateMooringSalesOrder, MooringSalesOrder, MooringSalesOrderListItem } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringSalesOrderService extends BaseApiService<MooringSalesOrder> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringSalesOrder);
  }

  public async table(filters: DataFilter) {
    if (filters.export) {
      // const f = await this.httpClient.execNoTransform(`api/serviceInvoices/invoiceable${filters.toQueryString()}`, {
      //   method: 'GET',
      // });
      // return f.blob().then((blob) => {
      //   const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
      //   saveAs(blob, `service-invoiceable-report-${dateString}.xlsx`);
      // });
    } else {
      return await this.httpClient.execWithResponseMeta<MooringSalesOrderListItem[]>(
        `${MooringSalesOrder.ApiUrl}/table${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }

  public async approve(id: number, IncludeAllCertificates: boolean = false) {
    return await this.httpClient.execNoTransform(`${MooringSalesOrder.ApiUrl}/${id}/approve`, {
      method: 'POST',
      body: JSON.stringify({ IncludeAllCertificates }),
    });
  }

  public async transferToAquacom(id: number) {
    return await this.httpClient.execNoTransform(`${MooringSalesOrder.ApiUrl}/${id}/transfer-aquacom`, {
      method: 'POST',
    });
  }

  public async create(data: CreateUpdateMooringSalesOrder) {
    return await this.httpClient.exec<MooringSalesOrder>(`${MooringSalesOrder.ApiUrl}/create`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async update(data: CreateUpdateMooringSalesOrder, id: number) {
    return await this.httpClient.exec<MooringSalesOrder>(`${MooringSalesOrder.ApiUrl}/${id}/update`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  public async unlock(id: number) {
    return await this.httpClient.execNoTransform(`${MooringSalesOrder.ApiUrl}/${id}/unlock`, {
      method: 'POST',
    });
  }

  public async checkOrderNumberExists(orderNumber: string): Promise<{ orderNumberExists: boolean }> {
    return await this.httpClient.exec(`${MooringSalesOrder.ApiUrl}/order-number-exists?orderNumber=${orderNumber}`, {
      method: 'GET',
    });
  }
}
