import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringCalculationReportService extends BaseApiService<Models.MooringCalculationReport> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.MooringCalculationReport);
    }

    public generateReport(report): Promise<any> {
        return this.post(report, '/generate-report');
    }
}
