import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CreateOrderDto } from './../models/OrderModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NetOrderItemService extends BaseApiService<Models.NetOrderItem> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.NetOrderItem);
    }

    public deleteNetOrderItem(netOrderItemId: number): Promise<any> {
        return this.delete(null, '/delete-net-order-item/' + netOrderItemId);
    }
}
