import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MarkingTypeService extends BaseApiService<Models.MarkingType> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.MarkingType);
    }
}
