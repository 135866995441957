import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CreateOrderDto } from './../models/OrderModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NetOrderService extends BaseApiService<Models.NetOrder> {
  constructor(
    httpClient: AuthHttpClient,
    private i18n: I18N
  ) {
    super(httpClient, Models.NetOrder);
  }

  public createOrder(orderData: CreateOrderDto): Promise<any> {
    return this.post(orderData as any, '/create-order');
  }

  public createNets(orderId: number): Promise<any> {
    return this.post(null, '/create-nets/' + orderId);
  }

  public deleteNetOrder(netOrderId: number): Promise<any> {
    return this.delete(null, '/delete-net-order/' + netOrderId);
  }

  public checkCanDeleteNetOrder(netOrderId: number): Promise<any> {
    return this.get('check-can-delete-order/' + netOrderId);
  }

  public getTotalPriceEstimate(orderId: number): Promise<any> {
    return this.get('get-total-price-estimate/' + orderId);
  }

  private getFilterStringFromFilter(filters) {
    let queryString = '';
    if (filters.orderStatusIds) {
      queryString += '?orderStatusIds=' + filters.orderStatusIds;
    } else {
      queryString += '?orderStatusIds=';
    }
    if (filters.orderCustomerId) {
      queryString += '&customerId=' + filters.orderCustomerId;
    }
    if (filters.orderProducerId) {
      queryString += '&producerId=' + filters.orderProducerId;
    }
    if (filters.orderResponsibleUserId) {
      queryString += '&responsibleUserId=' + filters.orderResponsibleUserId;
    }
    if (filters.orderSearchText) {
      queryString += '&searchText=' + filters.orderSearchText;
    }
    if (filters.orderOrderBy) {
      queryString += '&orderBy=' + filters.orderOrderBy;
    }
    if (filters.orderOrderByDirection) {
      queryString += '&orderByDirection=' + filters.orderOrderByDirection;
    }

    return queryString;
  }

  public getList(filters: any): Promise<any> {
    let queryString: string = this.getFilterStringFromFilter(filters);

    if (filters.orderTop) {
      queryString += '&top=' + filters.orderTop;
    }
    if (filters.orderSkip) {
      queryString += '&skip=' + filters.orderSkip;
    }
    if (filters.NetOfferId) {
      queryString += '&netOfferId=' + filters.NetOfferId;
    }

    return this.getResponse('getNetOrders' + queryString);
  }

  public exportList(filters: any): Promise<any> {
    let queryString: string = this.getFilterStringFromFilter(filters);

    queryString += '&export=true';
    queryString += '&locale=' + this.getLocale();

    return this.getBlob('getNetOrders' + queryString);
  }

  public exportDetailedReport(filters: any): Promise<any> {
    let queryString: string = this.getFilterStringFromFilter(filters);

    queryString += '&export=true';
    queryString += '&locale=' + this.getLocale();

    return this.getBlob('GetDetailedReport' + queryString);
  }

  private getLocale(): string {
    let locale = this.i18n.getLocale();

    if (locale.indexOf('NO') !== -1) {
      locale = 'NO';
    }

    return locale;
  }

  public async addOrderItem(orderId: number) {
    await this.post(null, `/${orderId}/order-items`);
  }
}
