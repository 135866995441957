import { autoinject } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import { Filters } from 'components/reports/newnet-month-report';
import { Models } from '../models/core';

@autoinject
export class NewnetReportService extends BaseApiService<Models.NewNetReport> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.NewNetReport);
  }

  public getNewnetMonthReport(): Promise<Array<any>> {
    const query = 'get-newnet-month-report';
    return <any>this.get(query);
  }

  public getNewnetMonthReportList(
    netFilters: Filters,
    exportMonthReport = false,
    selected?: string
  ): Promise<Response> {
    const params = this.getFilterQueryParams(netFilters);

    if (selected) {
      params.append('selected', selected);
    }

    if (exportMonthReport) {
      params.append('export', 'true');
      return <any>this.getBlob('get-newnet-month-report?' + params.toString());
    } else {
      return this.getResponse('get-newnet-month-report?' + params.toString());
    }
  }

  private getFilterQueryParams(netFilters: Filters): URLSearchParams {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(netFilters)) {
      if (value) {
        params.append(key, `${value}`);
      }
    }

    return params;
  }
}
