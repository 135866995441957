import { autoinject } from 'aurelia-framework';
import { DataFilter } from 'lib/tables/DataFilter';
import {
  CheckDuplicateMooringCertificate,
  CreateUpdateMooringCertificate,
  MooringCertificate,
  MooringCertificateListItem,
} from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringCertificateService extends BaseApiService<MooringCertificate> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringCertificate);
  }

  public async seach(query: string, articleNumber: string = '') {
    return await this.httpClient.exec<MooringCertificateListItem[]>(
      `${MooringCertificate.ApiUrl}/search?query=${query}&articleNumber=${articleNumber}`,
      {
        method: 'GET',
      }
    );
  }

  public async table(filters: DataFilter) {
    if (filters.export) {
      // const f = await this.httpClient.execNoTransform(`api/serviceInvoices/invoiceable${filters.toQueryString()}`, {
      //   method: 'GET',
      // });
      // return f.blob().then((blob) => {
      //   const dateString = new DateFormatValueConverter().toView(new Date(), 'DD-MM-YYYY');
      //   saveAs(blob, `service-invoiceable-report-${dateString}.xlsx`);
      // });
    } else {
      return await this.httpClient.execWithResponseMeta<MooringCertificateListItem[]>(
        `${MooringCertificate.ApiUrl}/table${filters.toQueryString()}`,
        {
          method: 'GET',
        }
      );
    }
  }

  public async frameTable(filters: DataFilter) {
    return await this.httpClient.execWithResponseMeta<MooringCertificateListItem[]>(
      `${MooringCertificate.ApiUrl}/table/frame${filters.toQueryString()}`,
      {
        method: 'GET',
      }
    );
  }

  public async create(data: CreateUpdateMooringCertificate) {
    return await this.httpClient.exec<MooringCertificate>(`${MooringCertificate.ApiUrl}/create`, {
      method: 'POST',
      body: this.createFormData(data),
      headers: {},
    });
  }

  public async update(data: CreateUpdateMooringCertificate, id: number) {
    return await this.httpClient.exec<MooringCertificate>(`${MooringCertificate.ApiUrl}/${id}/update`, {
      method: 'PATCH',
      body: this.createFormData(data),
      headers: {},
    });
  }

  public async preview(id: number) {
    const response = await this.httpClient.fetch(`${MooringCertificate.ApiUrl}/${id}/preview`, {
      method: 'GET',
    });
    const fileName = `certificate-${id.toString().padStart(6, '0')}-preview.pdf`;
    return {
      blob: await response.blob(),
      fileName,
    };
  }

  public async approve(id: number) {
    return await this.httpClient.execNoTransform(`${MooringCertificate.ApiUrl}/${id}/approve`, {
      method: 'POST',
    });
  }

  public async checkDuplicate(data: CheckDuplicateMooringCertificate) {
    return await this.httpClient.exec<{ IsDuplicate: boolean }>(`${MooringCertificate.ApiUrl}/checkDuplicate`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  public async unlock(id: number) {
    return await this.httpClient.execNoTransform(`${MooringCertificate.ApiUrl}/${id}/unlock`, {
      method: 'POST',
    });
  }

  private createFormData(data: CreateUpdateMooringCertificate) {
    const formData = new FormData();

    if (data.CertificateAttachment) {
      formData.append('CertificateAttachment', data.CertificateAttachment, data.CertificateAttachment.name);
    }
    if (data.ProducerDocumentationAttachment) {
      formData.append(
        'ProducerDocumentationAttachment',
        data.ProducerDocumentationAttachment,
        data.ProducerDocumentationAttachment.name
      );
    }

    Object.entries(data).forEach(([key, value]) => {
      if (key.includes('CertificateAttachment') || key.includes('ProducerDocumentationAttachment')) return;
      if (typeof value == 'undefined' || value == '') return;
      if (value instanceof Date) {
        value = value.toISOString();
      }
      formData.append(key, value);
    });
    return formData;
  }
}
