import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { AquacomComponentType, MooringArticleType } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringArticleTypeService extends BaseApiService<MooringArticleType> {

  constructor(httpClient: AuthHttpClient, private client: NetlogHttpClient) {
    super(httpClient, MooringArticleType);
  }

  async getAquacomTypes() {
    return (await this.client.get<AquacomComponentType[]>({
      url: `${MooringArticleType.ApiUrl}/aquacom`,
      model: AquacomComponentType
    })).data;

  }
}
