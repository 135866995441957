const languages = [
  {
    Id: 'nb-NO',
    Name: 'Norsk',
    NameEn: 'Norwegian',
    NameEs: 'Noruego',
  },
  {
    Id: 'en',
    Name: 'Engelsk',
    NameEn: 'English',
    NameEs: 'Inglés',
  },
  /*,
  // KE: remove for now, the language in the reports needs to be checked before using this
  {
      Id: 'es',
      Name: 'Spansk',
      NameEn: 'Spanish',
      NameEs: 'Español'
  }*/
] as const;

export type Language = (typeof languages)[number];

export class LanguageService {
  constructor() {}

  public getLanguages() {
    return languages;
  }
}
