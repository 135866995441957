import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MaterialService extends BaseApiService<Models.Material> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Material);
  }

  public async removeMaterialConstituent(materialId: number, materialConstituentId: number) {
    return this.httpClient.execNoTransform(
      `${Models.Material.ApiUrl}/${materialId}/constituents/${materialConstituentId}`,
      {
        method: 'DELETE',
      }
    );
  }
}
