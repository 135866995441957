import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { AquacomComponentCategory, MooringArticleCategory } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringArticleCategoryService extends BaseApiService<MooringArticleCategory> {

  constructor(httpClient: AuthHttpClient, private client: NetlogHttpClient) {
    super(httpClient, MooringArticleCategory);
  }

  async getAquacomCategoryTypes() {
    return (await this.client.get<AquacomComponentCategory[]>({
      url: `${MooringArticleCategory.ApiUrl}/aquacom`,
      model: AquacomComponentCategory
    })).data;
  }
}
