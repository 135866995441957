import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CreateOfferDto } from 'models/OfferModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NetOfferService extends BaseApiService<Models.NetOffer> {
    constructor(httpClient: AuthHttpClient, private i18n: I18N) {
        super(httpClient, Models.NetOffer);
    }

    public createOffer(offerData: CreateOfferDto): Promise<Models.NetOffer> {
      return this.post(offerData as any, '/create-offer');
    }

    public createOfferFromNet(offerData: CreateOfferDto): Promise<Models.NetOffer> {
      return this.post(offerData as any, '/create-offer-from-net');
    }

    public exportList(filters: any): Promise<any> {
      let queryString: string = this.getFilterStringFromFilter(filters);
      
      queryString += '&export=true';
      queryString += '&locale=' + this.getLocale();

      return this.getBlob('getNetOffers' + queryString);
    }

    public exportDetailedReport(filters: any): Promise<any> {
      let queryString: string = this.getFilterStringFromFilter(filters);
      
      queryString += '&export=true';
      queryString += '&locale=' + this.getLocale();

      return this.getBlob('GetDetailedReport' + queryString);
    }

    public getResponsibleUsers() : Promise <any> {
      return this.get('GetNetOffersResponsibleUsers');
    }

    public getList(filters: any): Promise<any> {
      const params = this.getFilterQueryParams(filters);

      return this.getResponse('getNetOffers?' + params.toString());
    }

    public getFilterQueryParams(filters: any): URLSearchParams {
      const params = new URLSearchParams();

      for (const [key, value] of Object.entries(filters)){
        if(value !== null && value !== undefined && value !== '') {
          params.append(key, `${value}`)
        }
      }
      return params;
    }

    private getFilterStringFromFilter(filters: any): string {
      let queryString = '';

      if (filters.offerStatusIds) {
        queryString += '?offerStatusIds=' + filters.offerStatusIds;
      } else {
        queryString += '?offerStatusIds=';
      }
      if (filters.customerId) {
        queryString += '&customerId=' + filters.customerId;
      }
      if (filters.responsibleUserId) {
        queryString += '&responsibleUserId=' + filters.responsibleUserId;
      }
      if (filters.searchText) {
        queryString += '&searchText=' + filters.searchText;
      }
      if (filters.orderBy) {
        queryString += '&orderBy=' + filters.orderBy;
      }
      if (filters.orderByDirection) {
        queryString += '&orderByDirection=' + filters.orderByDirection;
      }
      return queryString;
    }

    public deleteOffer(offerId: number): Promise<any> {
      return this.delete(null, '/delete-offer/' + offerId);
    }

    public getPriceCalculationItems(offerId: number): Promise<any> {
      return this.get('get-price-calculation-items/' + offerId);
    }

    public getTotalPriceEstimate(offerId: number): Promise<any> {
      return this.get('get-total-price-estimate/' + offerId);
    }
    
    private getLocale(): string {
      let locale = this.i18n.getLocale();

      if (locale.indexOf('NO') !== -1) {
        locale = 'NO';
      }

      return locale;
    }    
}
